.thead-for-print, .tfoot-for-print {
    display: none;
}

.print-header {
    position: fixed;
    top: 10px;
    width: 100%;
    text-align: center;
    display: none;
}

.print-footer {
    position: fixed;
    bottom: 10px;
    width: calc(100vw - 120px);
    display: none;
    justify-content: space-between;
}

.full-width {
    width: 100%;
}

.header-placeholder, .footer-placeholder {
    height: 50px;
}

@media print {
    .thead-for-print {
        display: table-header-group;

    }

    .tfoot-for-print {
        display: table-footer-group;
    }

    .print-header {
        display: block;
    }

    .print-footer {
        display: flex;
    }
}